import React from 'react';
import { TiWarning } from 'react-icons/ti';

const Warning = (props) => {
    const { body } = props
    return (
        <div className={"row"}>
            <div
                className={"col-lg-2 col-md-2 col-sm-2"}
                style={{
                    float: "left",
                    width: "45px",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignSelf: "center"
                }}
            ><TiWarning
                    style={{
                        marginLeft: "10px"
                    }}
                    size={35} /> </div>
            <div className={"col-lg-9 col-md-9 col-sm-9"} style={{paddingTop:10}} >
                <h1 style={{ fontSize: "17px", display: "flex" }}> {body} </h1>
            </div>
        </div>
    )
}

export default Warning
