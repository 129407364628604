import React from 'react';

const KatBookView = React.lazy(() => import('./views/KatBookView/KatBookView'));

const Book = React.lazy(() => import('./views/KBView/KBView'));

const TeacherView = React.lazy(() => import('./views/TeacherView/TeacherView'));

// const HolidayMasterEmployee = React.lazy(() => import('./views/HolidayMaster/HolidayEmployee'));
const StaffIndex = React.lazy(() => import('./views/StaffMapping/StaffIndex'));

// const HolidayMaster = React.lazy(() => import('./views/HolidayMaster/HolidayMaster'))
const Dashboard = React.lazy(()=> import('./views/katbookInstitation/KatBookInstition'))

const BookListForST =   React.lazy(()=> import('./views/KBView/BookListForST.js'));

const lzwcompress =   React.lazy(()=> import('./views/KBView/lzwcompress.js'));

const LinkContentView = React.lazy(()=>import('./views/ContentLink/ContentLinkView'));

const KatBookCreation = React.lazy(() => import('./views/BookCreation/BookCreation'));

const routes = [
  // Dashboard

  { path: '/', exact: true, name: 'Home', component: Dashboard, },

  // //KatBook
  // { path: '/katbookInitiation', name: 'Katbook Initiation', component: KatbookInstitation, },
  // // { path: '/content/create', name: 'Katbook Creation / Create Text Book & Notes', component: ContentCreate },
  // { path: '/CreateRole', exact: true, name: 'Administrator / Role Library / Create Role', component: CreateRole },
  // { path: '/RoleMenu', exact: true, name: 'Administrator / Role Library / Role Menu Privilege', component: RoleMenu },
  // { path: '/UserMenu', exact: true, name: 'Administrator / Role Library / User Menu Privilege', component: UserMenu },
  // // { path: '/NameofExercise', exact: true, name: 'Katbook Creation / Create Name of The Assessment', component: Exercise },
  // // { path: '/PostQuestionAndAnswer', exact: true, name: 'Katbook Creation / Create Question & Answer Bank', component: SlefAssessment },
  // { path: '/KatbookCreation', exact: true, name: 'Katbook Creation ', component: KatBookCreation },

  // { path: '/CreateUser', exact: true, name: 'Add User', component: CreateUser },
  // { path: '/CreateLink', exact: true, name: 'Katbook Creation / Create Advanced Content', component: CreateLink },
  // { path: '/exercise', exact: true, name: 'Role Menu', component: Exercise},
  { path: '/Location', exact: true, name: 'Administrator / Location Master', component: Location },
  // { path: '/StaffMapping', exact: true, name: 'Staff Mapping', component: StaffMapping },
  // { path: '/exercise', exact: true, name: 'Role Menu', component: Exercise},
  // { path: '/selfAssessment', exact: true, name: 'Role Menu', component: SlefAssessment},
  { path: '/KatBookView', exact: true, name: 'Katbook Creation / Katbook (Published)', component: KatBookView },
  // { path: '/OfficeType', exact: true, name: 'Type of Office', component: OfficeType },
  // { path: '/OfficeLocation', exact: true, name: 'Office Location', component: OfficeLocation },
  // { path: '/RoleCreation', exact: true, name: 'Role Creation', component: RoleCreation },
  // { path: '/CreateRole1', exact: true, name: 'Creat Role', component: CreateRole1 },
  // { path: '/RoleCopy', exact: true, name: 'Creat Role', component: RoleCopy },


  { path: '/KatBookView', exact: true, name: 'Katbook Creation / Katbook (Published)', component: KatBookView },
  // { path: '/EmployeeTile', exact: true, name: 'Employee Data', component: EmployeeTile },
  // { path: '/OrganizationSetup', exact: true, name: 'Employee Data', component: OrganizationTile },

  // { path: '/EmployeeData', exact: true, name: 'Employee Data', component: EmployeeData },
  // { path: '/EmployeeData/TabList', exact: true, name: 'Tabs List', component: TabsList },
  { path: '/BookView/:bookId/:book/:sId', exact: true, name: 'Book View', component: Book },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID/:refMediumID', exact: true, name: 'Kampus Book View', component: Book },
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },
  // {path:'/BookView/Content/:company/:country/:inst/:book/:obj/:crumb', exact: true, name: 'Book View Content', component:Book},
  { path: '/kampus/BookView/:bookId/:book/:sId/:refStandardID/:refSubjectID/:refInstID', exact: true, name: 'Kampus Book View', component: Book },
  // { path: '/HumanResourceTile', exact: true, name: 'Human Resource', component: HumanResourceTile },
  // { path: '/AdministratorTile', exact: true, name: 'Administrator', component: AdministratorTile },
  // { path: '/TypeOfBook', exact: true, name: 'Type Of Book', component: TypeOfBook },
  // { path: '/TypeInstitution', exact: true, name: 'Type Institution', component: TypeInstitution },

  // { path: '/AddUser', exact: true, name: 'Add User', component: AddUser },

  // { path: '/UserKey', exact: true, name: 'User Key', component: UserKey },
  // { path: '/Subscribe', exact: true, name: 'Subscribe', component: Subscribe },

  { path: '/TeacherView/:teacherCreds', exact: true, name: 'Teacher View / Standards', component: TeacherView },
  //  {path:'/TeacherView/:teacherCreds', exact:true,name:'Teacher View / Standards',component:TeacherView},
  // { path: '/ContentPreparation', exact: true, name: 'Content Preparation Activities ', component: ContentPreparation },
  // { path: '/ContentUploading', exact: true, name: 'Content Uploading Activities ', component: ContentUploading },
  // { path: '/ContentPreparationTile', exact: true, name: 'Activities Master ', component: ContentPreparationTile },
  // { path: '/PlanningScheduleTile', exact: true, name: 'Planning and Scheduling ', component: PlanningScheduleTile },
  // { path: '/AssignPreparation', exact: true, name: 'Planning and Scheduling ', component: AssignPreparation },
  // { path: '/ViewPreparation', exact: true, name: 'Planning and Scheduling ', component: ViewPreparation },
  // { path: '/ReSchedulingContent', exact: true, name: 'Planning and Scheduling ', component: ReSchedulingContent },
  // { path: '/ReScheduleUploading', exact: true, name: 'Planning and Scheduling ', component: ReScheduleUploading },

  { path: '/KatbookCreation', exact: true, name: 'Katbook Creation ', component: KatBookCreation },


  // { path: '/AssignUploading', exact: true, name: 'Planning and Scheduling ', component: AssignUploading },
  // { path: '/ViewUploading', exact: true, name: 'Planning and Scheduling ', component: ViewUploading },
  { path: '/StaffIndex', exact: true, name: 'Staff Subject Allocation', component: StaffIndex },
  
  // { path: '/HumanResourceTile/HolidayMAster', exact: true, name: 'Holiday Master', component: HolidayMaster },
  // { path: '/HumanResourceTile/HolidayMAsterEmployee', exact: true, name: 'Employee Based Holiday Setup', component: HolidayMasterEmployee },
  
  { path: '/katbook/bookList/:Creds', exact: true, name: 'Student Book List', component: BookListForST },

  { path: '/katbook/compress', exact: true, name: 'Compress View', component: lzwcompress },

  
  //Draft Content 
  // {path:'/Draft', exact:true,name:'Draft Katbbok Content',component:DraftContent},
  // // {path:'/Draft/UploadContent', exact:true,name:'Upload Draft Katbook Content',component:DraftContentUpload},
  // {path:'/Draft/Rework', exact:true,name:'Rework Draft Katbook Content',component:ReworkContentUpload},
  // {path:'/Draft/Approved', exact:true,name:'Rework Draft Katbook Content',component:AaprovedContentUpload},
  // {path:'/Approved/Draft', exact:true,name:'Approved Draft Katbook Content',component:ApprovedDraftContentTile},
  // {path:'/Approved/ApprovedDraftContent', exact:true,name:'Approved Draft Katbook Content',component:ApprovedDraftContent},
  // {path:'/Approved/ViewApprovedDraftContent', exact:true,name:'View Approved Content Katbook Content',component:ViewApprovedDraftContent},
  // {path:'/Approved/ViewCorrectionDraftContent', exact:true,name:'View Correction Draft Katbook Content',component:ViewCorrectionDraftContent},

  // {path:'/Draft/Upload', exact:true,name:'Upload Draft Katbook Content',component:DraftUpload},
  // {path:'/Draft/Approvel', exact:true,name:'Rework Draft Katbook Content',component:ApprovelDraft},
  // {path:'/Approved/Draft', exact:true,name:'Rework Draft Katbook Content',component:ApprovedDraftContentTile},
  { path: '/content/LinkView/:URL', exact: true, name: 'Content Link View', component: LinkContentView },

  
];

export default routes;
