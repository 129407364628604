import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  // CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CImg,
} from '@coreui/react'

// import {useHistory, useLocation } from 'react-router-dom'
// sidebar nav config
import Navigation from './_nav'
import img from '../assets/katbooklogo.png'
import { FaCogs } from 'react-icons/fa'
import { Authcontext } from 'src/views/components/Context/Context'
const TheSidebar = () => {
  // const history = useHistory();
  // const location = useLocation();
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const State = useContext(Authcontext);
  
  return (
    <CSidebar
      // className={"bg-gradient-primary"}
      // style={{backgroundColor:'#df0054'}}
      show={show}
      onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })}
    >
      <CSidebarBrand   onClick={() => {
            State.dispatch({type:"KBC_ROUTES", route:'', subRoute:''})
            }} className="d-md-down-none" to="/">
        <CImg
          className="c-sidebar-brand-full"
          src={`${img}`}
          height={35}
        
        />
        <FaCogs className="c-sidebar-brand-minimized" size={26} />
      </CSidebarBrand>
      <CSidebarNav >
        {/* <div  onClick={() => {
          // console.log(history.location.pathname.replace("\/", ""), State.state.route.replace(" ",""));
          if(State.State && State.State.route){
            let path = history.location.state;
            let active =  State.state.route.replace(" ","");
            console.log(path, active);
          if(path === active){
            return 0
          }}
          else{
          return State.dispatch({type:"KBC_ROUTES", route:'', subRoute:''})
          }
          }}> */}
        <CCreateElement
          items={Navigation(State)}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
        {/* </div> */}
      </CSidebarNav>
      {/* <CSidebarMinimizer className="c-d-md-down-none" /> */}
    </CSidebar>
  )
}

export default React.memo(TheSidebar)
