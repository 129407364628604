import React, {useContext} from 'react'
import { Authcontext } from 'src/views/components/Context/Context'
import {
  TheContent,
  TheSidebar,
  TheFooter,
  TheHeader
} from './index'

const TheLayout = ({style}) => {
const {state} = useContext(Authcontext)
  return (
    <>
    { !state.Book ?
    
    <div className="c-app c-default-layout">
      <TheSidebar/>
      <div className="c-wrapper">
        <TheHeader/>
        <div className="c-body">
          <TheContent/>
        </div>
        <TheFooter/>
      </div>
    </div>
    :<div className="c-app c-default-layout">
      <TheContent  />
      </div>
      
  }
    </>
  )
}

export default TheLayout
