import React,{useContext} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  // CHeaderNavItem,
  // CHeaderNavLink,
  CSubheader,
   CBreadcrumbRouter,
   CBreadcrumb,
   CBreadcrumbItem
  // CLink
} from '@coreui/react'
// import CIcon from '@coreui/icons-react'
import {Authcontext} from '../views/components/Context/Context'
// routes config
import routes from '../routes'
// import subRoutes from '../subRoutes'
import {
  TheHeaderDropdown,
    // TheHeaderDropdownMssg,
    // TheHeaderDropdownNotif,
    // TheHeaderDropdownTasks
} from './index'

const TheHeader = (props) => {
  const dispatch = useDispatch()
  const State = useContext(Authcontext);
  const sidebarShow = useSelector(state => state.sidebarShow)

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  }

  var styleTagStringContent = 
    ".talkify-activator-wrapper {"+
       "display: none!important;"+
    "}";


  return (
    <>
    <style type="text/css">
        {styleTagStringContent}
      </style>
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none " to="/">
        {/* <CIcon name="logo" color="white" height="48" alt="Logo" /> */}
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto " style={{flex:1, justifyContent:'center'}} >
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/users" >Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink >Settings</CHeaderNavLink>
        </CHeaderNavItem> */}
        <div style={{fontSize:15, fontWeight:'bold'}}>{State.state.userType === 'Teaching'?State.state.institutionName:State.state.companyName && State.state.companyName}</div>
      </CHeaderNav>
 
      <CHeaderNav className="px-3">
        {/* <TheHeaderDropdownNotif />
        <TheHeaderDropdownTasks />
        <TheHeaderDropdownMssg /> */}
        <TheHeaderDropdown toggle={props.toggle} />
      </CHeaderNav>
      
      <CSubheader className="px-3 justify-content-between">
        {!State.state.route ?<CBreadcrumbRouter 
          className="border-0 c-subheader-nav m-0 px-0 px-md-3" 
          routes={routes}
        >

        </CBreadcrumbRouter>:<CBreadcrumb style={{border:0, margin:"unset"}}>
            {/*eslint-disable-next-line*/}
      <CBreadcrumbItem ><a  onClick={() => {
          State.dispatch({type:"KBC_ROUTES", route:'', subRoute:''})
          }}
           href={State.state.url? `http://localhost:3000/#/TeacherView/eyJsb2duYW1lIjoidDQyMjIwMDUyIiwibG9ncGFzc3dvcmQiOiI4MTQwNDE1OCIsImluc3RpdHV0aW9uTmFtZSI6IkRvbiBCb3NjbyBNYXRyaWN1bGF0aW9uIEhpZ2hlciBTZWNvbmRhcnkgU2Nob29sIn0=`:"#"}>Home</a></CBreadcrumbItem>
      {/* eslint-disable-next-line*/}
      <CBreadcrumbItem active>{State.state.route}</CBreadcrumbItem>
      <CBreadcrumbItem active>{State.state.subRoute}</CBreadcrumbItem>
          </CBreadcrumb>}
         {/*  <div className="d-md-down-none mfe-2 c-subheader-nav">
            <CLink className="c-subheader-nav-link"href="#">
              <CIcon name="cil-speech" alt="Settings" />
            </CLink>
            <CLink 
              className="c-subheader-nav-link" 
              aria-current="page" 
              to="/dashboard"
            >
              <CIcon name="cil-graph" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
            <CLink className="c-subheader-nav-link" href="#">
              <CIcon name="cil-settings" alt="Settings" />&nbsp;Settings
            </CLink>
          </div>*/}
      </CSubheader> 
    </CHeader>
    </>
  )
}

export default TheHeader
