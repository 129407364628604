import { CButton, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CRow } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { getAllInstitutionUserLog, getAllLog, getKampusUserLogbyId } from 'src/Services/ApiService';
import { Authcontext } from '../Context/Context';
import Logger from '../UserLogger/Logger';

const UserLogModel = () => {
    const { state, dispatch } = React.useContext(Authcontext);
    const [log, setLog] = useState(state.log);

    console.log(state,"STATE LIST VALUES USER");

    useEffect(() => {
        const getkampusLogFuc = async () => {
            let result;

            try {
                // result = (state.userType === 'Student' || state.userType === 'Teacher') ? await getAllInstitutionUserLog() : await getAllLog();
                result = await getKampusUserLogbyId({
                    id: state.creds.userName
                });
                // result = await getKampusUserLogbyId({
                //     id: state.creds.userName
                // });
                if (result.success === true) {
                    console.log(result, "result123");
                    setLog(result.logs);
                }
            } catch (e) {
               console.log(e);
            }
        }

        const getAllLogFuc = async () => {
            let result;

            try {
                // result = (state.userType === 'Student' || state.userType === 'Teacher') ? await getAllInstitutionUserLog() : await getAllLog();
                result = await getAllLog();
                // result = await getKampusUserLogbyId({
                //     id: state.creds.userName
                // });
                console.log(result, "result123");
                if (result.success === true) {
                    console.log(result, "result123");
                    setLog(result.logs);
                }
            } catch (e) {
               console.log(e);
            }
        }
        if (state) {

            if(state.userType === 'Student' || state.userType === 'Teacher'){
                getkampusLogFuc();
            } else {
                getAllLogFuc();
            }
            
        }
    }, [state]);



    return (
        <>
            <CModal className={"katB_log_lpQ"}
                show={state.userLogger}
                onClose={() => { dispatch({ type: "UserLogger" }); }}
            >
                <CModalHeader closeButton><h6 className={"user_log_Ktitle"} >User Log</h6></CModalHeader>
                <CModalBody>
                    <div >
                        <CRow>
                            <CCol><h6><strong>Platform</strong></h6></CCol>
                            <CCol><h6><strong>Browser</strong></h6></CCol>
                            <CCol><h6><strong>Login Date &amp; Time</strong></h6></CCol>
                            <CCol><h6><strong>IP Address</strong></h6></CCol>
                        </CRow>
                        <div style={{ maxHeight: '100%', height: 250, overflowY: 'scroll', overflowX: 'hidden', width: '100%' }}>
                            {log && log.length > 0 && log.map((x) => (<>
                                <Logger data={x} />
                            </>))}
                        </div>
                    </div>
                </CModalBody>
                <CModalFooter>
                    <CButton
                        color="danger"
                        onClick={() => { dispatch({ type: "UserLogger" }); }}
                    >Cancel</CButton>{' '}
                </CModalFooter>
            </CModal>
        </>
    )
}

export default UserLogModel
