import React from 'react';

const initialState = {
  family: [],
  experience: [],
  education: [],
  document: [],
  tabShown: false
};

export const EmployeeContext = React.createContext(initialState);
 const EmployeeContextFuc = ({ children }) => {
  const [state, setState] = React.useState(initialState);

  return (
    <EmployeeContext.Provider value={[state, setState]}>
      {children}
    </EmployeeContext.Provider>
  );
};

export default EmployeeContextFuc