import { CCol, CRow } from '@coreui/react';
import moment from 'moment';
import React from 'react'
import { FaChrome, FaEdge, FaFirefoxBrowser, FaLinux, FaMobile, FaOpera, FaSafari, FaWindows, MdDesktopMac, MdWebAsset } from 'react-icons/all';

const Logger = (props) => {
    const { data } = props;
    return (
        <>
            <hr />
            <CRow key={data._id} style={{ padding: "5px 0px 5px 0px;" }} >
                <CCol ><h6>
                    {data.source.isMobile && <FaMobile size={20} />}
                    {data.source.isWindows && <FaWindows size={20} />}
                    {data.source.isLinux64 && <FaLinux size={20} />}
                    {data.source.isMac && <MdDesktopMac size={20} />}
                    {'   '}{data.source.platform==="unknown"? data.source.source:data.source.platform}</h6></CCol>
                <CCol><h6>
                    {data.source.isSafari && <FaSafari size={20} />}
                    {data.source.isOpera && <FaOpera size={20} />}
                    {data.source.isChrome && <FaChrome size={20} />}
                    {data.source.isEdge && <FaEdge size={20} />}
                    {data.source.isFirefox && <FaFirefoxBrowser size={20} />}
                    {data.source.isBot === "postman" && <MdWebAsset size={20} />}
                    {'   '}{data.browser}</h6></CCol>
                <CCol><h6>{moment(data.createdAt).format('lll')}</h6></CCol>
                <CCol><h6>{data.ipv4}</h6></CCol>
            </CRow>
        </>
    )
}

export default Logger
