const GlobalReducer = (state, action) => {
  switch (action.type) {
    case "ENTITY":
      return {
        ...state,
        entity: action.value,
      };
    case "TOKEN":
      return {
        ...state,
        token: action.value,
      };
  
  case "PROFILE":
      return {
        ...state,
        profile: action.value,
        token: action.token,
      };
  
  case "FILTER":
    return {
      ...state,
      filter: action.values
    }

    default :
    return {
      ...state,
    }
     
  };

  
};

export default GlobalReducer;
